// Sass libs
@use 'sass:math';

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "tailwindcss/screens";

// Responsive lib "Include-media"
@import "./libs/include-media/breakpoints";

// Custom SCSS
@import "./abstracts/_root";
@import "./abstracts/_fonts";
@import "./components/_components";
@import "./abstracts/_keyframes";
@import "./components/_mixins";

// PhotoSwipe lib style
@import "./libs/photoswipe/css/main.scss";
@import "./libs/photoswipe/css/default-skin/default-skin.scss";

#app {
    min-height: 100vh;
    overflow-y: hidden;
    @include preloader;
}

nav {
    @include front-nav;
}

.hero-banner {
    @include home-hero;
}

.home-intro {
    @include home-intro;
}

.home-partners {
    @include home-partners;
}

.partners-container {
    @include partners-container;
}

.news-header {
    @include news-header;
}

.contact-container {
    @include contact;
}

footer {
    @include footer;
}