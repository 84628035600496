@mixin preloader {
    #Preloader {
        opacity: 0;
        position: absolute;
        z-index: -1;
        visibility: hidden;
        transition: position 0s 1s, visibility 0s 1s, z-index 0s 1s, opacity 1s 0s;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: white;
        
        &.active {
            opacity: 1;
            visibility: visible;
            position: fixed;
            z-index: 20;
            
        }
        img,
        button,
        lottie-player {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

@mixin front-nav {

    .nav-icon {

        &>div {
            border-radius: 50%;
            padding: 10px;
            background-color: var(--white);
            box-shadow: var(--shadow-def);
            min-width: 1.3rem;
            transition: box-shadow .5s ease;

            &:hover,
            &:active,
            &:focus {
                box-shadow: var(--shadow-light);
            }

            svg {
                stroke: var(--black);
                width: 1.5em;
                height: auto;
                max-height: 100%;
                max-width: 100%;
            }
        }
    }

    .vertical-nav-container {
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 20;
        transition-property: transform;
        
        &.close {
            transform: translateX(-100%);
            transition-delay: .5s;

            .vertical-nav,
            .overlay {
                transform: translateX(-100%);
            }
        }
        
        .vertical-nav {
            position: fixed;
            top: 0;
            left: 0;
            height: 100%;
            min-width: 16.5rem;
            width: 20%;
            max-width: 25rem;
            background-color: var(--white);
            box-shadow: var(--shadow-dark);
            transition: transform .5s ease-in-out;
            transform: translateX(0);

            .close-icon svg {
                stroke: var(--black);
                min-width: 1.3rem;
                cursor: pointer;
                width: 2rem;
                height: auto;
                cursor: pointer;
                border: none;
                box-shadow: none;
                padding: 0;
            }

            .tabs-container {
                width: 100%;
                padding: 3rem 0 0 10%;

                .nav-item {
                    max-width: 1200px;
                    margin: 0 auto;
                    text-align: left;
                    display: flex;
                    align-items: center;
                    margin-bottom: .5rem;

                    a {
                        width: fit-content;
                        font-size: 1.4rem;

                        &.active {
                            text-decoration: underline;
                        }
                    }
                }
            }

            .nav-footer {
                position: absolute;
                bottom: 1em;
                left: 50%;
                transform: translateX(-50%);

                .nav-logo {
                    width: 4em;
                    max-width: 100%;
                    margin: 0 auto;
                }

                .nav-footer-links {
                    text-align: center;
                    display: flex;
                    width: 100%;

                    a {
                        margin: 0 .5em;
                        color: var(--grey-def);
                        font-size: .8rem;
                        transition: color .5s ease-in-out;

                        &:hover {
                            color: var(--black);
                        }
                    }
                }
            }
        }
    }

    .top-nav {
        position: fixed;
        top: 0;
        height: 3rem;
        display: flex;
        width: 100vw;
        max-width: 100%;
        padding: 1rem 2rem;
        justify-content: space-between;
        align-content: center;
        z-index: 5;

        .nav-icon {
            cursor: pointer;
        }

    }


}

@mixin home-hero {

    min-height: 250px;
    height: 30vh;
    width: 100%;

    .hero-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        padding: 1rem;
        flex-wrap: nowrap;

        .hero-logo {
            width: 100%;
            max-width: 8rem;
        }

        .title-hero {
            text-align: left;
            font-size: 3.5rem;
            margin-bottom: 0;
            margin-left: 1.5rem;
        }

        .text-hero {
            text-align: center;
            border-top: solid 1px var(--white);
            margin-top: 0;
        }
    }

}

@mixin home-intro {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 5rem 0;
    width: 100%;

    &.visible {

        .intro-img,
        .intro-text {
            opacity: 1;
        }
    }

    .intro-img {
        height: auto;
        min-width: 260px;
        width: 33%;
        max-width: 420px;
        margin: 0 2rem;
        border: solid 2px var(--black);
        opacity: 0;
        transition: opacity .5s linear .3s;

        @include media("<=lg") {
            width: 100%;
        }
    }

    .intro-text {
        width: 66%;
        min-width: 19rem;
        max-width: 42rem;
        opacity: 0;
        transition: opacity .5s linear;

        @include media("<=lg") {
            width: 100%;
        }
    }

    .intro-text h2,
    .intro-text h1 {
        text-align: left;
        font-size: 2rem;
    }

    .intro-text p {
        text-align: justify;
    }
}

@mixin home-partners {

    margin: 4rem auto;
    padding-top: 2rem;
    padding-bottom: 2rem;

    .partners-title {
        text-align: center;
    }

    .partners-photo-container {
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        position: relative;

        @include media("<=sm") {
            flex-wrap: wrap;
        }

        .partner-img {
            transition: transform .5s ease-out;

            img {
                border-top: solid 2px var(--black);
                border-bottom: solid 2px var(--black);
                transition: filter .2s;

                @include media("<=sm") {
                    border-inline: solid 2px var(--black);
                    border-block: none;
                }

                &:hover {
                    filter: brightness(0.9);
                }
            }
        }

        .partner1 {
            clip-path: polygon(75% 0, 75% 52%, 100% 52%, 100% 100%, 0 100%, 0 0);
            transform: translateX(10%);
            
            @include media("<=sm") {
                clip-path: polygon(0 0, 100% 0, 100% 48%, 51% 48%, 51% 100%, 0 100%);
                transform: translateY(25%);
            }

            img {
                border-left: solid 2px var(--black);

                @include media("<=sm") {
                    border-top: solid 2px var(--black);
                }
            }
        }

        .partner2 {
            clip-path: polygon(100% 0, 100% 100%, 25% 100%, 25% 48%, 0 48%, 0 0);
            transform: translateX(-11%);
            
            @include media("<=sm") {
                clip-path: polygon(0 52%, 54% 52%, 54% 0, 100% 0, 100% 100%, 0 100%);
                transform: translateY(-24%);
                margin-bottom: -5rem;
            }

            img {
                border-right: solid 2px var(--black);

                @include media("<=sm") {
                    border-bottom: solid 2px var(--black);
                }
            }
        }
    }

    .partners-name {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;

        a {
            margin-inline: .5rem;
        }
    }


}

@mixin partners-container {
    max-width: 1200px;
    margin: 0 auto;

    &.visible .partner1-photo {
        animation: slideLeft 1.5s;
        opacity: 1 !important;
    }

    &.visible .partner2-photo {
        animation: slideRight 1.5s;
        opacity: 1 !important;
    }

    .partners {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2em;
        justify-content: center;
        align-items: stretch;

        @include media("<=md") {
            grid-template-columns: 1fr;
        }

        img {
            width: 100%;
            height: auto;
            margin: 0 auto;
        }

        .partner-photo {
            opacity: 0;
            max-width: 80%;
            margin: 0 auto;
        }

        .partner1-photo:hover ~ .partners-content .partner2-content,
        .partner2-photo:hover ~ .partners-content .partner1-content {
            opacity: .5;
        }

        .partner-content {
            transition: opacity .5s;
            text-align: center;
        }
    }

}

@mixin news-header {

    .news-intro {
        margin-top: 2rem;
    }

    .filters {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        text-align: right;
        flex-wrap: nowrap;
        margin-block: 2rem;

        .filters-container {
            display: flex;
            flex-wrap: wrap;
            text-align: center;
            justify-content: flex-end;

            &.active {
   
                .filter {
                    opacity: 1;
                    visibility: visible;
                    position: static;
                    transition: position 0s .5s, visibility 0s .5s;
                }

                $filters: 50;

                @for $i from 1 through $filters {
                    .filter-#{$i} {
                        transition: opacity .2s linear (.5s + (1s - math.div($i, 10)));
                    }
                }
            }



            .filter {
                margin-inline: .25rem;
                color: var(--grey-def);
                cursor: pointer;
                position: absolute;
                opacity: 0;
                visibility: hidden;

                &:hover,
                &.active {
                    color: var(--black);
                }
            }
        }

        .filter-btn {
            min-width: fit-content;
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            gap: .2em;
            margin-inline: 1rem;
            cursor: pointer;

            &.active svg  {
                transform: rotate(90deg)
            }

            svg {
                width: 1em;
                transition: transform .5s;
            }
        }
    }

}

@mixin contact {
    margin-bottom: 5rem;

    .form-container {
        margin-block: 3rem;
        display: grid;
        grid-template-columns:3fr 2fr;
        border: solid 1px var(--black);
        border-radius: var(--border-radius);
        box-shadow: var(--shadow-light);
        justify-content: center;

        @include media("<=md") {
            grid-template-columns: 1fr;
        }

        .form-fields {
            padding: 2rem 1rem;

            .infos-container {
                margin-bottom: 2rem;
                
                .infos {
                    margin-bottom: 2rem;
                }

                .form-input {
                    margin-inline: auto;
                    display: block;
                }
            }

            .captcha-container {

                #checkboxCaptcha {
                    display: none;
                }

                #messageCaptcha {
                    text-align: center;
                    margin-bottom: 1rem;
                }

                .captcha {
                    display: grid;
                    grid-template-columns: 1fr 2rem 1fr;
                    -moz-column-gap: 5px;
                    column-gap: 5px;
                    margin: 20px auto 0;
                    text-align: center;
                }

                $captcha-size: 65px;

                .logo-captcha {
                    background-size: cover;
                    width: $captcha-size;
                    height: $captcha-size;
                    border: solid 1px var(--grey-def);
                }

                .piece-captcha {
                    width: $captcha-size;
                    height: $captcha-size;
                    border: solid 1px var(--grey-def);
                    margin: 0 0 0 auto;
                }

                .helper-icon {
                    align-self: center;
                    margin-inline: auto;
                    
                    svg {
                        stroke: var(--grey-def);
                    }
                }
            }

            .contact-send {
                margin-left: auto;
                display: flex;

                @include media("<=md") {
                    margin-right: auto;
                }

                svg {
                    stroke: white;
                }
            }
        }
    }
}

@mixin footer {
    margin-top: 2rem;
    display: flex;

    @include media("<=md") {
        display: block;
    }

    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .footer-content {
        width: 33.33%;
        min-width: 15rem;
        display: flex;
        flex-wrap: nowrap;
        gap: .2rem;

        @include media("<=md") {
            width: 100%;
            margin: 0 auto;
        }

        svg {
            width: 100%;
            max-width: 1rem;
            margin-bottom: .1rem;
        }
    }

    .footer-links {
        justify-content: flex-start;

        @include media("<=md") {
            justify-content: center;
        }
    }

    .footer-host {
        justify-content: center;
        display: inline-block;
        text-align: center;

        svg {
            margin: 0 auto;
            max-width: 7rem;
        }
    }

    .footer-love {
        justify-content: flex-end;

        @include media("<=md") {
            justify-content: center;
        }
    }
}
