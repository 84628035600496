@keyframes shrink {
    0% {
        -webkit-clip-path: circle(50%);
                clip-path: circle(50%);
    }
    100% {
        -webkit-clip-path: circle(0%);
                clip-path: circle(0%);
    }
}

@keyframes gridHide {
    0% {
        opacity: 1;
        pointer-events: inherit;
        position: static;
        visibility: inherit;
    }
    99% {
        opacity: 0;
    }
    100% {
        pointer-events: none;
        position: absolute;
        visibility: hidden;
    }
}

@keyframes slideRight {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideLeft {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}