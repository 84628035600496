// The purpose of this SCSS file is to create reusable display component

::selection {
    background: var(--grey-def);
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background-color: var(--grey-light);
}

::-webkit-scrollbar-thumb {
    background-color: var(--grey-dark);
}

main > section {

    @include media(">=xl") {
        padding-inline: calc(1rem + 15vw);
    }

    @include media("<=sm") {
        padding-inline: 1rem;
    }

    padding-inline: calc(1rem + 5vw);
    z-index: -1;
}

mark {
    background-color: var(--black);
    color: var(--white);
    padding: 5px;
    line-height: 2.43rem;
}

@for $i from 1 through 4 {
    h#{$i} {
        margin: 1rem 0;
        font-family: 'Perpetua', serif;
        font-size: (.25rem + math.div(4, $i));
        font-weight: normal;
        line-height: 1;
    }
}

p {
    font-size: 1rem;
    font-family: 'Heebo', sans-serif;
}

img {
    border-radius: var(--border-radius);
}

a {
    color: var(--black);
    text-decoration: none;
    font-weight: bold;
}

input {
    accent-color: var(--black);
}

main.content h1.main-title {
    margin: 1.5em auto 0.5em;
    font-size: 4rem;
}

.btn {
    font-family: 'Heebo', sans-serif;
    font-weight: bold;
    display: inline-flex;
    gap: .5rem;
    padding: .5rem 1rem;
    margin: .2rem .5rem;
    cursor: pointer;
    color: var(--white);
    background-color: var(--black);
    min-width: 10rem;
    max-width: 20rem;
    max-height: 3rem;
    transition: .4s;
    justify-content: center;
    box-shadow: var(--shadow-light);
    outline-color: var(--black);
    outline-width: 1px;
    outline-style: solid;
    outline-offset: .2em;
    transition: outline-offset, outline;
    transition-duration: .5s;

    &:hover,
    &:active {
        box-shadow: var(--shadow-def);
        outline-offset: -.2em;
        outline-color: var(--white);
    }

    &:focus {
        outline-style: dotted;
        outline-offset: .1rem;
        outline-color: var(--yellow-def);
        outline-width: .15rem;
    }

    &.btn-danger {
        background-color: var(--red-def);
        border-color: var(--red-def);
        outline-color: var(--red-def);
        
        &:hover {
            outline-color: var(--white);
        }
    }

    &.btn-light {
        background-color: var(--white);
        color: var(--black);
        outline-color: var(--white);

        &:hover,
        &:active {
            outline-color: var(--black);
        }
    }

    &.disabled {
        filter: brightness(0.5);
        cursor: not-allowed;
    }

}

.absolute-center {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
}

.btn-sm {
    padding: .2rem .5rem;
    font-weight: normal;
    font-size: .9rem;
    min-width: auto;
}

.bg-image-1 {
    background-image: url('/images/assets/layout/hero-banner.jpg');
    background-color: var(--black);
    background-position: top;
    background-size: contain;
    background-attachment: fixed;
    position: relative;
    z-index: 2;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--grey-dark);
    transition: background-color .5s ease-in-out;
    backdrop-filter: blur(1px);
    z-index: -1;
}

.outline {
    outline: solid 1px var(--white);
    outline-offset: -.3em;
    border-radius: 0 !important;
    border: none !important;
}

.link-hover {
    background: linear-gradient(to top, var(--black) 0%, var(--black) 10%, transparent 10.1%) no-repeat;
    background-size: 0 100%;
    transition: background-size .3s;

    &:hover,
    &:active {
        background-size: 100% 100%;
    }
}

.cta-container {
    margin: 5rem 0;
    padding: 3rem 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .cta-content {
        text-align: center;
        color: var(--white);
        min-width: 25rem;
        max-width: 40rem;
        width: 66%;

        @include media("<=md") {
            min-width: 100%;
            width: 100%;
        }
    }

    .cta-btn {
        text-align: center;
        width: 33%;

        @include media("<=md") {
            width: 100%;
            margin-top: 1rem;
        }
    }
}

.tag-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0;
    padding: 0 !important;

    .tag-item {
        padding: .2rem .4rem;
        margin: .2rem .2rem;
        background-color: var(--grey-light);
        list-style: none;
    }
}

.card-container {
    &.visible {
        .cards .card {
            opacity: 1;
        }
    }

    .cards {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: .5rem;
        justify-content: center;
        align-items: stretch;

        @include media('<=lg') {
            grid-template-columns: repeat(2, 1fr);
        }

        @include media('<=sm') {
            grid-template-columns: 1fr;
        }

        $cards: 10;

        @for $i from 1 through $cards {
            .card-#{$i} {
                transition: opacity .5s linear (0s + math.div($i, 5));
            }
        }

        .card {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            margin: 0 auto;
            justify-content: flex-start;
            text-align: center;
            border: solid 2px var(--black);
            border-radius: var(--border-radius);
            opacity: 0;

            &.nactive {
                display: none;
            }

            &.card-article {
                max-width: 28rem;
                margin: .2rem .5rem;
            }

            .card-header {
                position: relative;
                min-width: 100%;
                height: 150px;
                overflow: hidden;
            }

            .card-header img {
                transform: translate(-50%, -50%);
                position: absolute;
                top: 50%;
                left: 50%;
                overflow: hidden;
            }

            .card-body {
                padding: 1em .5em;
                
                svg {
                    display: block;
                    margin: 0 auto;
                    width: 3rem;
                    height: auto;
                    max-height: 4rem;
                }
                
                .card-desc {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                }
            }

            .card-footer {
                color: var(--grey-def);
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;
                margin-bottom: .2rem;
            }
        }
    }

    .cards-2 {
        grid-template-columns: repeat(2, 1fr);

        @include media("<=md") {
            grid-template-columns: 1fr;
        }
    }
}

.modalHandler {
    display: inline-block;
    cursor: pointer;
    transition: background-color .2s linear;

    &:hover {
        background-color: var(--grey-light);
    }
}

.sep {
    border-bottom: solid 1px var(--grey-def);
    margin-bottom: 1rem;
}

.tiny-content {

    ul {
        padding-left: 2rem;
        margin-block: 2rem;

        li {
            list-style: disc;
        }
    }
}

#Like {
    color: var(--grey-def);
    
    &:hover {
        color: var(--grey-dark);
    }
    
    svg,
    img {
        cursor: pointer;
        margin-left: 1rem;
    }

     .active svg,
     .active img {
        fill: var(--grey-light);
    }
}

.modal-container {
    margin: 1rem 1rem;
    position: fixed;
    bottom: 2em;
    right: 0;
    z-index: 10;
    display: grid;
    grid-template-columns: 3em 1fr;
    align-items: center;
    gap: .2rem;
    box-shadow: var(--shadow-def);
    border-top: solid 2px var(--black);
    background-color: var(--white);
    max-width: 20rem;
    min-width: 10rem;
    padding: 1rem;
    animation: slideRight 1s linear;

    .modal-close {
        position: absolute;
        right: .5em;
        top: .2em;
        cursor: pointer;

        svg {
            width: 1.3rem;
            height: 1.3rem;
        }
    }

    .modal-icon {
        margin: 0 auto;
    }

    .modal-content {

        .modal-title {
            font-weight: bold;
        }
    }
}

#CookieNotice {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 15;
    margin-block: .5rem;
    
    .cookie-notice {
        background: var(--white);
        padding: .2rem 1rem;
        color: var(--black);
        border-top: solid 2px var(--black);
        max-width: 35rem;
        min-width: 18rem;
        position: relative;
        text-align: center;
        box-shadow: var(--shadow-def);
        
        .cookie-btn {
            margin-top: .2rem;
        }
    }
}

.form-container {

    $input-w: 30rem;

    input:not([type='checkbox'], [type='submit'], [type='radio']),
    textarea,
    select {
        box-shadow: 3px 3px 0px 0px var(--grey-def);
        transition: .5s;
        outline: none;

        &:focus,
        &:active {
            box-shadow: 5px 5px 0px 0px var(--grey-dark);
        }
    }

    input:not([type="radio"], [type="checkbox"]),
    select,
    textarea {
        max-width: $input-w;
        min-width: 7rem;
    }

    .form-block {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: .5rem;
    }

    .form-title {
        text-align: center;
    }

    .form-input {
        position: relative;
        padding: 0.5rem 1rem;
        margin: .5rem .2rem;
        border-style: solid;
        border-width: 1px;
        border-color: var(--grey-dark);
        width: 100%;
    }

    .form-errors {
        background-color: var(--red-light);
        border: 1px solid var(--red-def);
        padding: 1rem;

        .form-error {
            color: var(--red-def);
        }
    }

    .honeypot {
        opacity: 0;
        visibility: hidden;
        cursor: none;
        position: absolute;
    }

    // Compatibility issue with React-select component
    .tox .tox-editor-header {
        z-index: 0;
    }
    
    .multipleSelect {
        input {
            box-shadow: none;

            &:focus,
            &:active {
                box-shadow: none;
            }
        }
    }

    input[type=checkbox].form-check {
        display: none;
        
        &,
        &:after,
        &:before,
        & *,
        & *:after,
        & *:before,
        &+.form-check-label {
            box-sizing: border-box;

            &::selection {
                background: none;
            }
        }

        +.form-check-label {
            outline: 0;
            width: 2em;
            height: 1.1em;
            position: relative;
            cursor: pointer;
            user-select: none;
            padding: 2px;
            transition: all .2s ease;
            background: var(--white);
            border: 2px solid var(--grey-def);

            &:after,
            &:before {
                position: relative;
                display: block;
                content: "";
                width: 50%;
                height: 100%;
            }

            &:after {
                left: 0;
                transition: all .2s ease;
                background: var(--grey-def);
                content: "";
            }

            &:before {
                display: none;
            }
        }

        &:checked+.form-check-label {
            border: 2px solid var(--green-def);
        }

        &:checked+.form-check-label:after {
            left: 50%;
            left: 50%;
            background: var(--green-def);
        }
    }
    
}
