@font-face {
    font-family: 'Perpetua';
    src: url('/fonts/perpetua-regular.woff2') format('woff2'),
        url('/fonts/perpetua-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Perpetua';
    src: url('/fonts/perpetua-bold.woff2') format('woff2'),
        url('/fonts/perpetua-bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}