// Define variables used in other SCSS and in Tailwind configs
:root {
    --blue: rgb(46, 48, 139);
    --yellow: #fdee2e;
    --red-def: #c5223b;
    --red-light: rgba(197, 34, 59, 0.2);
    --green-def: #19a59a;
    --green-light: rgba(25, 165, 154, 0.2);
    --grey-light: rgba(0, 0, 0, 0.04);
    --grey-def: rgba(0, 0, 0, .35);
    --grey-dark: rgba(0, 0, 0, 0.851);
    --white: #fff;
    --white-light: rgba(255, 255, 255, 0.5);
    --black: #000;
    --shadow-light: 0 0 3px 0 rgba(0, 0, 0, 0.2);
    --shadow-def: 0 0 5px 0 rgba(134, 51, 51, 0.3);
    --shadow-dark: 0 0 15px rgba(0, 0, 0, 0.5);
    --drop-shadow: drop-shadow(0 0 15px rgba(0, 0, 0, 0.1));
    --drop-shadow-xl: drop-shadow(0 0 15px rgba(0, 0, 0, 0.5));
    --border-radius: .2em;
}